const useQuestsPageData = () => {
	const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
	const { isSeasonQuest, data, refresh: refreshQuestsPageData } = useGetPageQuestData({ immediate: false });
	const { refresh: refreshQuestData } = useQuestData({ immediate: false });

	const playerDays = computed(() => data.value?.playerData?.days ?? []);
	const isAllowedGameMode = computed(() => {
		if (isSeasonQuest.value) {
			return false;
		}

		if (isSweepStakes.value) {
			return data.value?.quest?.isSweepStakesModeAvailable;
		}

		return data.value?.quest?.isTournamentPointsModeAvailable;
	});

	const isShowBanner = computed(() =>
		Boolean(
			!isSeasonQuest.value &&
				((isAllowedGameMode.value && data.value?.quest) || (!data.value?.quest && data.value?.futureQuest))
		)
	);

	const isShowNotAvailableMode = computed(
		() => !data.value?.playerData?.days?.length || (!isAllowedGameMode.value && !isShowBanner.value)
	);
	return {
		data,
		playerDays,
		isAllowedGameMode,
		isShowBanner,
		isShowNotAvailableMode,
		handleSwitchGameMode,
		isSweepStakes,
		refreshQuestsPageData,
		refreshQuestData,
		isSeasonQuest
	};
};

export default useQuestsPageData;
